
import { Options, Vue } from 'vue-class-component';
import drinks from './json/data.json'

@Options({
  components: {},
  data() {
    return {
      openmenu: 'local',
      selector: 0,
      openResults: false,
      showNumbers: false,
      catalogue: drinks
    }
  },
  methods: {
    selectChange(e: { target: { value: string; }; }) {
      if ( e.target.value !== '0' ) {
        location.hash = "#" + e.target.value + '-select'
        this.selector = 0
      }
    },
    consume(inc: number, i: string|number, j: string|number) {
      if (this.catalogue[i].sizes[j].count > 0 || inc == +1) {
        this.catalogue[i].sizes[j].count = this.catalogue[i].sizes[j].count + inc
      }
    },
    clearCalculator() {
      this.catalogue.map(function (item: { sizes: { ml: number; count: number; }[]; alc: number; cal: number; }) {
        item.sizes.map(function (size: { ml: number; count: number; }) {
           size.count = 0
        })
      })
    },
    filter_catalogue(cat: string) {
      return this.catalogue.filter((drink: { category: string; }) => drink.category == cat);
    }
  },
  computed: {
    drink_totals() {
      let cheeseburger = 303
      let walking = 57
      let running = 12

      let calories = 0
      let units = 0
      let cb = 0
      let kw = 0
      let mr = 0

      this.catalogue.map(function (item: { sizes: { ml: number; count: number; }[]; alc: number; cal: number; }) {
        let amount = item.sizes.map(function (size: { ml: number; count: number; }) {
           units += (size.ml * item.alc / 1000) * size.count
           calories += (size.ml * item.cal) * size.count
        })
        return amount
      })
      units = Math.round(units * 10) / 10
      calories = Math.round(calories)
      cb = Math.round((calories / cheeseburger) * 10) / 10
      kw = Math.round((calories / walking) * 10) / 10
      mr = Math.round((calories / running) * 10) / 10
      
      return {units: units, cal: calories, cb: cb, kw: kw, mr: mr}
    }
  }
})
export default class Home extends Vue {}
